export type ThemePaths = "default" | "orangeburg";
export const theme = {
  spacing: 4,
  /* Note: fontFamily should NOT be edited unless the new font is installed first */
  fontFamily: "Inter",
  fonts: {
    weights: {
      normal: 400,
      bold: 500,
      mediumBold: 600,
      bolder: 700,
    },
  },
  backgrounds: {
    main: "#F6F6F6",
    widget: "#FFFFFF",
    primarySelection: "#F8FCF9",
    secondarySelection: "#F7FAFD",
    tertiarySelection: "#e6e6e6",
  },
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.04), inset 0px 0px 1px #BABABF",
  default: {
    colors: {
      mutedText: "#12373f8a",
      brandPrimaryForegroundColor: "#d7f1da",
      activeElementBackground: "#e8e8e8",
      white: "#fff",
      dark: "#000000",
      primary: {
        900: "#1E3E26",
        700: "#2E613B",
        500: "#3F8450",
        300: "#97CEA5",
        50: "#EEF7F0",
      },
      secondary: {
        900: "#0F2034",
        700: "#1A395B",
        500: "#255183",
        300: "#88B0DD",
        50: "#EBF2F9",
      },
      textPrimary: {
        primary: "#434343",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
        heading: "#253331",
        headingAlt: "#488b5b",
        cardText: "#434343",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
      border: {
        primary: "#E4E7E5",
        secondary: "#434343",
      },
      hover: {
        primary: "#f9f9f9",
      },
      buttons: {
        primary: {
          ButtonBg: "#416661",
          HvButtonBg: "#55857e",
          ActiveButtonBg: "#02575c",
          BtText: "#ffffff",
          borderColor: "#55857e",
        },
        disabled: {
          ButtonBg: "#E2E2E2",
          HvButtonBg: "#E2E2E2",
          ActiveButtonBg: "#E2E2E2",
          BtText: "rgb(109, 109, 109)",
        },
        selectButton: {
          ButtonBg: "#55857e",
          HvButtonBg: "#55857e",
          ActiveButtonBg: "#02575c",
          BtText: "#ffffff",
          borderColor: "transparent",
          disabled: {
            ButtonBg: "#E2E2E2",
            HvButtonBg: "#E2E2E2",
            ActiveButtonBg: "#E2E2E2",
            BtText: "rgb(109, 109, 109)",
          },
        },
        outline: {
          border: "#D0D5DD",
          createAccount: "#000",
        },
      },
      links: {
        primary: "#497C76",
        hoverColor: "#497C76",
        externalLink: "#0299FD",
        entgreen: "#253331",
        teal: "#55857e",
      },
      backgrounds: {
        default: "#fbfffe",
        fallback: "#7ab8b0",
        asideGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
      },
      icons: {
        default: {
          fillColor: "#416661",
          secondaryFillColor: "#434343",
          starFillColor: "#f9cb28",
        },
        viewBy: {
          defaultBg: "transparent",
          activeBg: "#d7f1da",
          hoverBg: "#e8e8e8",
          activeColor: "#ffffff",
        },
      },
      stepper: {
        activeBg: "#02575C",
        activeColor: "#ffffff",
        completeBg: "#d7f1da",
        completeColor: "#000",
        defaultBg: "#c5c5c5",
        defaultColor: "#000",
        fillColor: "#ffffff",
        secondaryIconFill: "#04A7F8",
      },
      cardwrapper: {
        shadow: "#c9dad899",
        footer: {
          background: {
            default: "#d7f1da",
            secondary: "#ffd2d2",
          },
        },
      },
      servicecard: {
        primary: "#303632",
        secondary: "#6d6d6d",
        border: "#e8e8e8",
        link: "#1D19E0",
      },
      notification: {
        success: {
          primary: "#4CAF81",
          accent: "#84e88e",
        },
        error: {
          primary: "#ec0000",
          accent: "#ffd2d2",
        },
        warning: {
          secondary: "#E53934",
          primary: "#f9cb28",
          accent: "#F84D04",
          secondaryAccent: "#fce8b6",
        },
      },
      form: {
        primary: "#6d6d6d",
        label: {
          primary: "#c5c5c5",
        },
      },
      table: {
        secondary: "#a9a9a9",
        primary: "#6d6d6d",
        background: "#f0f0f0",
        border: "#c5c5c5",
        evenCell: "#FAFAFA",
        pagination: {
          disabled: "#aeadad",
          primary: "#416661",
          bgPrimary: "#416661",
          bgActive: "#DBDBDB",
          bgDisabled: "#FAFAFA",
          bgHover: "#f0f0f0",
          border: {
            primary: "#416661",
            secondary: "#DBDBDB",
          },
        },
      },
      menu: {
        item: {
          selectedBG: "#FAFAFA",
          hoverBG: "#FAFAFA",
          focusVisibleBG: "#FAFAFA",
        },
      },
    },
    gradients: {
      greenMenuGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
    },
    boxShadows: {
      boxShadowBR: "8px 8px 10px -1px rgba(0,0,0,0.20)",
    },
  },
  orangeburg: {
    colors: {
      mutedText: "#12373f8a",
      brandPrimaryForegroundColor: "#d7f1da",
      activeElementBackground: "#e8e8e8",
      white: "#fff",
      dark: "#000000",
      primary: {
        900: "#5D2600",
        700: "#DE5A00",
        500: "#DE5A00",
        300: "#DE5A00",
        50: "#FFE0CA",
      },
      secondary: {
        900: "#5D2600",
        700: "#DE5A00",
        500: "#DE5A00",
        300: "#DE5A00",
        50: "#FFE0CA",
      },
      textPrimary: {
        primary: "#303632",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
        heading: "#190A00",
        headingAlt: "#DE5A00",
        cardText: "#434343",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
      border: {
        primary: "#E4E7E5",
        secondary: "#434343",
      },
      hover: {
        primary: "#DE5A00",
      },
      servicecard: {
        primary: "#190A00",
        secondary: "#6d6d6d",
        border: "#e8e8e8",
        link: "#1D19E0",
      },
      buttons: {
        primary: {
          ButtonBg: "#DE5A00",
          HvButtonBg: "#DE5A00",
          ActiveButtonBg: "#DE5A00",
          BtText: "#ffffff",
          borderColor: "#DE5A00",
        },
        disabled: {
          ButtonBg: "rgb(226, 226, 226)",
          HvButtonBg: "#E2E2E2",
          ActiveButtonBg: "#E2E2E2",
          BtText: "rgb(109, 109, 109)",
        },
        selectButton: {
          ButtonBg: "#DE5A00",
          HvButtonBg: "#DE5A00",
          ActiveButtonBg: "#DE5A00",
          BtText: "#ffffff",
          borderColor: "transparent",
          disabled: {
            ButtonBg: "#E2E2E2",
            HvButtonBg: "#E2E2E2",
            ActiveButtonBg: "#E2E2E2",
            BtText: "rgb(109, 109, 109)",
          },
        },
        outline: {
          border: "#D0D5DD",
          createAccount: "#000",
        },
      },
      links: {
        primary: "#DE5A00",
        hoverColor: "#DE5A00",
        externalLink: "#0299FD",
        entgreen: "#253331",
        teal: "#55857e",
      },
      backgrounds: {
        default: "#ffffff",
        fallback: "#CDDDEC",
        asideGradient: "linear-gradient(180deg, #CDDDEC 0%, #CDDDEC 87%)",
      },
      icons: {
        default: {
          fillColor: "#E57B33",
          secondaryFillColor: "#434343",
          starFillColor: "#f9cb28",
        },
        viewBy: {
          defaultBg: "transparent",
          defaultColor: "",
          activeBg: "#E57B33",
          activeColor: "#ffffff",
          hoverBg: "#e8e8e8",
        },
      },
      stepper: {
        activeBg: "#E57B33 ",
        activeColor: "#ffffff",
        completeBg: "#FFE0CA",
        completeColor: "#000",
        defaultBg: "#c5c5c5",
        defaultColor: "#000",
        fillColor: "#ffffff",
        secondaryIconFill: "#04A7F8",
      },
      cardwrapper: {
        shadow: "#c9dad899",
        footer: {
          background: {
            default: "#d7f1da",
            secondary: "#ffd2d2",
          },
        },
      },
      notification: {
        success: {
          primary: "#4CAF81",
          accent: "#84e88e",
        },
        error: {
          primary: "#ec0000",
          accent: "#ffd2d2",
        },
        warning: {
          secondary: "#E53934",
          primary: "#f9cb28",
          accent: "#F84D04",
          secondaryAccent: "#fce8b6",
        },
      },
      form: {
        primary: "#6d6d6d",
        label: {
          primary: "#c5c5c5",
        },
      },
      table: {
        primary: "#6d6d6d",
        secondary: "#a9a9a9",
        background: "#f0f0f0",
        border: "#c5c5c5",
        evenCell: "#FAFAFA",
        pagination: {
          disabled: "#6D6D6D",
          primary: "#DE5A00",
          bgPrimary: "#DE5A00",
          bgActive: "#DBDBDB",
          bgHover: "#f0f0f0",
          bgDisabled: "#FAFAFA",
          border: {
            primary: "#DE5A00",
            secondary: "#DBDBDB",
          },
        },
      },
      menu: {
        item: {
          selectedBG: "#FAFAFA",
          hoverBG: "#FAFAFA",
          focusVisibleBG: "#FAFAFA",
        },
      },
    },
    gradients: {
      greenMenuGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
    },
    boxShadows: {
      boxShadowBR: "8px 8px 10px -1px rgba(0,0,0,0.20)",
    },
  },
  wraps: "wrap !important",
} as const;
