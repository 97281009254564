import {
  Box,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Input from "../../components/Input";
import Label from "../../components/Label";
import CityIcon from "../../components/CityIcon";
import Button from "../../components/Button";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import getREMFromPX from "../../utils/getREMFromPX";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import { EyeShowIcon, EyeHideIcon, MailIcon } from "../../assets/icons";
import useViewport from "../../hooks/useViewport";
import isValidPassword from "../../utils/isValidPassword";
import PasswordValidation from "../../components/Validation/Password";
import ConfirmPasswordValidation from "../../components/Validation/ConfirmPassword";
import useSupportEmail from "../../hooks/useSupportEmail";
import {
  iconStyleAdornment,
  iconStylePad,
  inputAdornmentStyle,
} from "../Login/Styles";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import sendErrorToast from "../../utils/sendErrorToast";
import { toast } from "react-toastify";
import { routes } from "../../App";

interface UpdatePasswordFormValues {
  password: string;
  confirmPassword: string;
}

const UpdatePassword = () => {
  const themePath = useGetThemePath();
  const {
    watch,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<UpdatePasswordFormValues>({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const { userInfo, setUserInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { mutateAsync: updateUser, isLoading } = useUpdateUser(
    userInfo?.data?.user?.id ?? "",
    {
      onError: () => sendErrorToast("There was an error activating the user."),
    }
  );

  const password = watch("password").trim();
  const confirmPassword = watch("confirmPassword").trim();
  const { isMobile } = useViewport();

  const onSubmit = async (data: UpdatePasswordFormValues) => {
    const { password } = data;
    try {
      const response = await updateUser({ password, force_password: false });

      if (response.data) {
        setUserInfo(response.data);
        toast.success("Password updated successfully");
        window.location.replace(routes.dashboard.path);
      }
    } catch (error) {
      toast.error("Error while updating password, Please try again!");
    }
  };

  const colors = theme[themePath].colors;

  const passwordsMatch = confirmPassword === password;
  const supportEmail = useSupportEmail();

  const handleRedirectToEmail = () => {
    window.open(`mailto:${supportEmail}`, "_blank", "noopener,noreferrer");
  };

  const shouldSubmit = Object.keys(errors).length === 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          mx: "auto",
          width: "100%",
          display: "flex",
          justifyContent: {
            lg: "start",
            xs: "center",
          },
          alignItems: "center",
          pt: "4.5rem",
          pb: 1,
          pl: {
            lg: "5rem",
            xs: 0,
          },
        }}
      >
        <CityIcon />
      </Box>
      <Stack
        flexDirection="row"
        flex={1}
        justifyContent="center"
        alignItems="flex-start"
        pb="4.5rem"
        bgcolor={colors.white}
      >
        <Stack flex={1} maxWidth={"540px"} alignItems="center">
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: isMobile ? getREMFromPX(30) : getREMFromPX(36),
              fontWeight: 600,
              lineHeight: "44px",
              letterSpacing: "0em",
              marginBottom: isMobile ? "8px" : "26px",
              textAlign: "center",
            }}
          >
            Create Password
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              size: "16px",
              lineHeight: "20px",
              textAlign: {
                lg: "start",
                md: "center",
              },
              px: 2,
            }}
          >
            For your security, you're required to create a new password. Your
            temporary password will not be valid after this step. Please ensure
            your new password meets our security criteria.
          </Typography>
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: 460,
              padding: "0 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Stack
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "100%", mt: "6px !important" }}>
                  <Label htmlFor="password">New Password</Label>
                  <Input
                    autoComplete="current-password"
                    {...register("password", {
                      required: true,
                      validate: (value) => {
                        return (
                          isValidPassword(value) ||
                          "Password must contain at least 8 characters and less than 16, at least 1\nuppercase and 1 lowercase letter, 1 number and 1 special character."
                        );
                      },
                    })}
                    required
                    error={
                      (!!errors.password && !!password.length) ||
                      !passwordsMatch
                    }
                    softError={
                      !!errors.password || !password.length || !passwordsMatch
                    }
                    type={showPassword ? "text" : "password"}
                    id="password"
                    style={inputAdornmentStyle}
                    endAdornment={
                      <InputAdornment position="end" style={iconStyleAdornment}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          style={iconStylePad}
                          data-cy="togglePassVisibility"
                        >
                          {showPassword ? (
                            <EyeHideIcon
                              fill={colors.icons.default.fillColor}
                            />
                          ) : (
                            <EyeShowIcon
                              fill={colors.icons.default.fillColor}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <PasswordValidation password={password} />
                </Stack>
                <Stack style={{ width: "100%" }}>
                  <Label htmlFor="confirmPassword">Confirm Password</Label>
                  <Input
                    data-testid="confirmPassword"
                    id="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    error={
                      (!!errors.confirmPassword && !!confirmPassword.length) ||
                      !passwordsMatch
                    }
                    softError={
                      !!errors.confirmPassword ||
                      !confirmPassword.length ||
                      !passwordsMatch
                    }
                    required
                    {...register("confirmPassword", {
                      validate: (value) => value === getValues().password,
                    })}
                    style={inputAdornmentStyle}
                    endAdornment={
                      <InputAdornment position="end" style={iconStyleAdornment}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          style={iconStylePad}
                          data-cy="togglePassConfirmVisibility"
                        >
                          {showPassword ? <EyeHideIcon /> : <EyeShowIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Stack mt="0.5rem">
                    <ConfirmPasswordValidation
                      confirmPassword={password}
                      passwordsMatch={passwordsMatch}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Box sx={{ width: "100%", marginTop: isMobile ? "16px" : "44px" }}>
              <Button
                text="Confirm"
                mode="secondary"
                type="submit"
                disabled={!shouldSubmit || !passwordsMatch || isLoading}
                isLoading={isLoading}
                sx={{ width: "100%", marginBottom: "24px" }}
              />
            </Box>
            {!isMobile && (
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "35px",
                  cursor: "pointer",
                }}
                onClick={handleRedirectToEmail}
              >
                <MailIcon fill={colors.icons.default.fillColor} />
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    lineHeight: "15px",
                    color: "#253331",
                    letterSpacing: "0.015rem",
                    textDecoration: "underline",
                  }}
                >
                  &nbsp;{supportEmail}
                </Typography>
              </Stack>
            )}
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};

export default UpdatePassword;
