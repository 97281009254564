import { Box, Typography, TableRow, TableCell } from "@mui/material";
import getREMFromPX from "../../../../utils/getREMFromPX";
import useViewport from "../../../../hooks/useViewport";

const MyDevicePortsRow = ({ service }: { service: any }) => {
  const { isMobile } = useViewport();

  if (Object.keys(service).length < 1) {
    return null;
  }

  const isActive = service?.status === "ACTIVE";
  const portActiveColor = isActive ? "#84e88e" : "#d9d9d9";
  const portName = service?.Portinterface?.display_name;

  const cyRow = `ports-table-row-${service.id}`;

  const badgeStyles = isActive
    ? {
        color: "#027A48",
        background: "#ECFDF3",
      }
    : {
        color: "#ff1a1a",
        background: "#ffd8d8",
      };

  return (
    <TableRow data-cy={cyRow}>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "70px",
            background: "#fff",
            border: "1px solid #EFEFEF",
            borderRadius: "4px",
            padding: "4px 0px",
          }}
        >
          {portName}
          <Box
            sx={{
              color: portActiveColor,
              background: portActiveColor,
              width: "10px",
              height: "10px",
              borderRadius: "10px",
              border: "1px solid " + portActiveColor,
              marginLeft: "4px",
            }}
          >
            &nbsp;
          </Box>
        </Box>
      </TableCell>

      {isMobile ? (
        <>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Provider:{" "}
              </Typography>
              <span key={service.id}>
                {service?.Serviceplan?.Service?.Provider?.name || ""}
              </span>
            </Typography>
            <Typography fontSize={getREMFromPX(16)}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Plan:{" "}
              </Typography>
              <span key={service.id}>{service?.Serviceplan?.name || ""}</span>
            </Typography>
            <Typography fontSize={getREMFromPX(16)}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Type:{" "}
              </Typography>
              <span key={service.id}>
                {service?.Serviceplan?.Service?.Servicetype.name || ""}
              </span>
            </Typography>
            <Typography
              sx={{
                padding: "2px 8px 2px 8px",
                borderRadius: "20px",
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                marginTop: "4px",
                ...badgeStyles,
              }}
              fontSize={getREMFromPX(10)}
            >
              {isActive ? "Connected" : "Disconnected"}
            </Typography>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              <div key={service.id}>
                {service?.Serviceplan?.Service?.Provider?.name || ""}
              </div>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              <div key={service.id}>{service?.Serviceplan?.name || ""}</div>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              <div key={service.id}>
                {service?.Serviceplan?.Service?.Servicetype.name || ""}
              </div>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              sx={{
                padding: "2px 8px 2px 8px",
                borderRadius: "20px",
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                ...badgeStyles,
              }}
              fontSize={getREMFromPX(10)}
            >
              {isActive ? "Connected" : "Disconnected"}
            </Typography>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default MyDevicePortsRow;
