import _ from "lodash";
import {
  Box,
  Typography,
  Stack,
  Paper,
  ClickAwayListener,
  Menu,
  MenuItem,
} from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import { Account } from "../../api/interfaces/Account";
import sendErrorToast from "../../utils/sendErrorToast";
import { useIsDeviceOn } from "../../hooks/useIsDeviceOn";
import RouterIcon from "@mui/icons-material/Router";
import useViewport from "../../hooks/useViewport";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext, useState } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import EmptyMyServicesMessage from "../../routes/Services/components/EmptyMyServicesMessage";
import StyledLink from "../Link";
import Loader from "../Loader";
import { useGetConfig } from "../../hooks/useGetConfig";
import useGetThemePath from "../../hooks/useGetThemePath";
import { Device } from "../../api/interfaces/Device";
import MyServicesTable from "./MyServicesTable";
import { ChevronLeft } from "@mui/icons-material";

interface MyServicesProps {
  subscriptions: Array<Account>;
  isLoadingSubscriptions: boolean;
}

const MyServices = ({
  subscriptions,
  isLoadingSubscriptions,
}: MyServicesProps) => {
  const { setCurrentDevice, currentDevice, devices } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { data } = useGetConfig();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isUserRatingEnabled = Boolean(
    data?.data.config.enabled_features.USER_REVIEWS.enabled
  );

  const optionsDevices = devices?.map((item: Device, i: number) => ({
    label: `Device ${i + 1} - ${item?.Physicallocation?.house_number} ${
      item?.Physicallocation?.street
    }`,
    value: item?.id,
  })) as Array<{
    label: string;
    value: string;
  }>;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const findDevice = (deviceId: string) =>
    devices.find((device) => device.id === deviceId);

  const listWithoutDuplicatedRecords = optionsDevices.filter(
    (item, index, arr) => {
      const firstIndex = arr.findIndex(
        (element) => element.value === item.value
      );
      return index === firstIndex;
    }
  );

  const { data: isDeviceOn } = useIsDeviceOn(currentDevice.id, {
    refetchOnWindowFocus: false,
    enabled: Boolean(currentDevice?.id),
    onError: () =>
      sendErrorToast("There was an error getting the device information"),
  });

  const { isMobile } = useViewport();

  const [openDevicesList, setOpenDevicesList] = useState<boolean>(false);

  const currentSubscriptions = subscriptions?.filter(
    (sub: any) => sub.Portinterface.device_id === currentDevice.id
  );

  if (isLoadingSubscriptions) {
    return (
      <Box
        sx={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Stack data-cy="myServicesContainer">
      {isMobile ? (
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <RouterIcon
              sx={{
                height: 32,
                width: 32,
              }}
              fill={theme[themePath].colors.icons.default.fillColor}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: `1px solid ${colors.border.primary}`,
                height: "32px",
                borderRadius: "4px",
                justifyContent: "center",
                px: 1,
                gap: 1,
              }}
              onClick={handleClick}
            >
              <Typography
                fontSize={getREMFromPX(16)}
                sx={{
                  fontWeight: "400",
                  fontFamily: "Inter",
                  color: colors.form.primary,
                  width: "130px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {`${currentDevice?.Physicallocation?.house_number} ${currentDevice?.Physicallocation?.street}`}
                asdfasdfa
              </Typography>
              <ChevronLeft
                sx={{
                  rotate: "-90deg",
                }}
                fill={theme[themePath].colors.icons.default.fillColor}
              />
            </Box>
            <Menu
              MenuListProps={{
                sx: {
                  width: "calc(100vw - 48px)",
                },
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {listWithoutDuplicatedRecords.map(({ label, value }) => (
                <MenuItem
                  sx={{
                    fontSize: getREMFromPX(16),
                    fontFamily: "Inter",
                    color: colors.form.primary,
                    fontWeight: "400",
                  }}
                  onClick={() => {
                    const newDevice = findDevice(value);
                    if (newDevice) {
                      setCurrentDevice(newDevice);
                    }
                  }}
                  key={label}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {isDeviceOn && (
            <Box
              sx={{
                height: "20px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 12px",
                backgroundColor:
                  isDeviceOn.data.device === "ON" ? "#ECFDF3" : "#ffd7b3",
              }}
            >
              <Typography
                fontFamily="Inter"
                fontSize={getREMFromPX(12)}
                color={isDeviceOn.data.device === "ON" ? "#027A48" : "#ff6c27"}
              >
                {isDeviceOn.data.device === "ON" ? "On" : "Off"}
              </Typography>
            </Box>
          )}
        </Stack>
      ) : null}
      <Typography
        mt={{
          md: 0,
          xs: 1,
        }}
      >
        You can manage your current services on this page. To add more services,
        please visit the "All Services" page to explore and subscribe to
        additional options.
      </Typography>
      <Box
        sx={{
          border: {
            md: "1px solid #DBDBDB",
            xs: "none",
          },
          borderRadius: "8px",
          marginTop: "16px",
        }}
      >
        {!isMobile ? (
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            display="flex"
            alignItems="center"
            marginBottom={!isMobile ? "16px" : ""}
            sx={{
              backgroundColor: colors.table.background,
              padding: 1.5,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <Box marginRight="8px">
              <RouterIcon
                sx={{
                  height: 32,
                  width: 32,
                }}
                fill={theme[themePath].colors.icons.default.fillColor}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              onClick={() => setOpenDevicesList(!openDevicesList)}
              sx={{
                cursor: "pointer",
                position: "relative",
              }}
            >
              <Typography
                fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
                fontWeight={700}
                color="#000"
                fontFamily="Inter"
                marginRight="12px"
              >
                {`${currentDevice?.Physicallocation?.house_number} ${currentDevice?.Physicallocation?.street}`}
              </Typography>

              {devices.length > 1 && (
                <>
                  <ArrowDropDownIcon
                    fill={theme[themePath].colors.icons.default.fillColor}
                  />
                  {!!openDevicesList && (
                    <ClickAwayListener
                      onClickAway={() => setOpenDevicesList(false)}
                    >
                      <Paper
                        sx={{
                          position: "absolute",
                          zIndex: 10,
                          padding: "16px 8px",
                          width: isMobile ? "200px" : "237px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 25px 0 rgba(0, 0, 0, 0.25)",
                          top: "34px",
                          left: isMobile ? "20px" : "130px",
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            fontSize={getREMFromPX(14)}
                            color={colors.textPrimary.heading}
                            fontFamily="Inter"
                            fontWeight={600}
                          >
                            Switch Device
                          </Typography>
                          {listWithoutDuplicatedRecords.map((val) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                marginTop: "20px",
                              }}
                              onClick={() => {
                                const newDevice = findDevice(val.value);
                                if (newDevice) {
                                  setCurrentDevice(newDevice);
                                }
                              }}
                              key={val.value}
                            >
                              <RouterIcon
                                fill={
                                  theme[themePath].colors.icons.default
                                    .fillColor
                                }
                              />
                              <Typography
                                fontSize={getREMFromPX(14)}
                                color={colors.textPrimary.heading}
                                fontFamily="Inter"
                              >
                                {val.label}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Paper>
                    </ClickAwayListener>
                  )}
                </>
              )}
            </Box>
            <Typography
              fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(20)}
              marginRight="12px"
            >
              |
            </Typography>
            <Typography
              fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
              fontWeight={500}
              fontFamily="Inter"
              marginRight="6px"
            >
              Serial Number: {`${currentDevice?.serial_number}`}
            </Typography>
            {isDeviceOn && (
              <Box
                sx={{
                  height: "20px",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "4px 16px",
                  marginLeft: "8px",
                  backgroundColor:
                    isDeviceOn.data.device === "ON" ? "#84e88e" : "#ff7a00",
                }}
              >
                <Typography
                  fontFamily="Inter"
                  fontSize={isMobile ? getREMFromPX(7.4) : getREMFromPX(12)}
                  color={isDeviceOn.data.device === "ON" ? "#0b8718" : "#fff"}
                >
                  {isDeviceOn.data.device === "ON" ? "On" : "Off"}
                </Typography>
              </Box>
            )}

            <StyledLink
              sx={{
                background: "#fff",
                border: `1px solid ${colors.buttons.outline.border}`,
                fontSize: 14,
                fontWeight: 500,
                color: colors.form.primary,
                textAlign: "center",
                borderRadius: "4px",
                width: "fit-content",
                marginLeft: "auto",
                padding: "2px 16px",
              }}
              to="/help/device"
              data-testid="device-info-link"
            >
              Device Info
            </StyledLink>
          </Stack>
        ) : null}

        <Box
          sx={{
            padding: {
              md: "0px 14px 14px 14px",
              xs: "0px",
            },
            td: {
              padding: {
                md: "0px 16px",
                xs: "12px 16px",
              },
            },
            overflow: "auto",
          }}
        >
          <MyServicesTable
            currentSubscriptions={currentSubscriptions}
            isUserRatingEnabled={isUserRatingEnabled}
          />
          {_.isEmpty(currentSubscriptions) ? <EmptyMyServicesMessage /> : null}
        </Box>
      </Box>
    </Stack>
  );
};

export default MyServices;
