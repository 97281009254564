import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NavContainer";
import CardWrapper from "../../../../components/CardWrapper";
import { BackStyle } from "../../Styles";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { LocationIcon, RotateIcon } from "../../../../assets/icons";

import {
  VbgTitle,
  VbgInfo,
  StreetInfo,
  Router,
  Hardware,
  MyService,
  ViewMyService,
  MyServiceRow,
} from "./Styles";
import { ButtonService } from "../../Styles";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import MyDevicePortsRow from "../../components/DeviceGuide/MyDevicePortsRow";
import useViewport from "../../../../hooks/useViewport";
import RouterIcon from "@mui/icons-material/Router";
import {
  ONTDeviceFront,
  ONTDeviceBack,
  VenoenDeviceFront,
  VenoenDeviceBack,
  AaeonDeviceFront,
  AaeonDeviceBack,
} from "../../../../assets/device";

import { useIsPortLive } from "../../../../hooks/useIsPortLive";
import SelectDevice from "../../../../components/SelectDevice";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { useIsDeviceOn } from "../../../../hooks/useIsDeviceOn";
import sendErrorToast from "../../../../utils/sendErrorToast";
import { useGetMySubscriptions } from "../../../../hooks/useGetMySubscriptions";
import useGetUserId from "../../../../hooks/useGetUserId";
import Loader from "../../../../components/Loader";
import React from "react";

const processData = (subscriptionsData: any, currentDevice: any) => {
  const subscriptions = (subscriptionsData?.data?.subscription || []).map(
    (subscriptionData: any) => {
      return subscriptionData;
    }
  );

  const currentSubscriptions = subscriptions?.filter(
    (sub: any) => sub.Portinterface.device_id === currentDevice.id
  );

  return currentSubscriptions;
};

const DeviceGuide = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const navigate = useNavigate();

  const backAction = (
    <BackStyle aria-label="Back to Help" to={routes.help.path}>
      Back to Help
    </BackStyle>
  );

  const { currentDevice, devices, currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const portsWithSubscriptions = currentDevice?.Portinterfaces?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.Subscriptions?.length > 0
  );

  const ports = currentDevice?.Portinterfaces;

  const lanOnePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 1"
  );

  const lanOnePort = lanOnePorts ? lanOnePorts[0] : null;

  const { data: liveLanOnePort, isLoading: isLanport1Loading } = useIsPortLive(
    lanOnePort?.device_id ?? "",
    lanOnePort?.id ?? "",
    {
      enabled: Boolean(lanOnePort?.device_id) && Boolean(lanOnePort?.id),
    }
  );

  const lanOnePortWithLive = {
    ...lanOnePort,
    ...liveLanOnePort,
  } as any;

  const lanTwoPorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 2"
  );

  const lanTwoPort = lanTwoPorts ? lanTwoPorts[0] : null;

  const { data: liveLanTwoPort, isLoading: isLanport2Loading } = useIsPortLive(
    lanTwoPort?.device_id ?? "",
    lanTwoPort?.id ?? "",
    {
      enabled: Boolean(lanTwoPort?.device_id) && Boolean(lanTwoPort?.id),
    }
  );

  const lanTwoPortWithLive = {
    ...lanTwoPort,
    ...liveLanTwoPort,
  } as any;

  const lanThreePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 3"
  );

  const lanThreePort = lanThreePorts ? lanThreePorts[0] : null;

  const { data: liveLanThreePort, isLoading: isLanport3Loading } =
    useIsPortLive(lanThreePort?.device_id ?? "", lanThreePort?.id ?? "", {
      enabled: Boolean(lanThreePort?.device_id) && Boolean(lanThreePort?.id),
    });

  const lanThreePortWithLive = {
    ...lanThreePort,
    ...liveLanThreePort,
  } as any;

  const lanFourPorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 4"
  );

  const lanFourPort = lanFourPorts ? lanFourPorts[0] : null;

  const { data: liveLanFourPort, isLoading: isLanport4Loading } = useIsPortLive(
    lanFourPort?.device_id ?? "",
    lanFourPort?.id ?? "",
    {
      enabled: Boolean(lanFourPort?.device_id) && Boolean(lanFourPort?.id),
    }
  );

  const lanFourPortWithLive = {
    ...lanFourPort,
    ...liveLanFourPort,
  } as any;

  const sfpOnePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "SFP 1"
  );

  const sfpOnePort = sfpOnePorts ? sfpOnePorts[0] : null;

  const { data: liveSfpOnePort, isLoading: isLanportSfpLoading } =
    useIsPortLive(sfpOnePort?.device_id ?? "", sfpOnePort?.id ?? "", {
      enabled: Boolean(sfpOnePort?.device_id) && Boolean(sfpOnePort?.id),
    });

  const sfpOnePortWithLive = {
    ...sfpOnePort,
    ...liveSfpOnePort,
  } as any;

  const deviceHaveSubscription =
    (portsWithSubscriptions?.length ?? 0) > 0 ? true : false;

  const wanPorts = currentDevice?.Portinterfaces?.filter(
    (portinterface) =>
      portinterface.display === true && portinterface.type === "WAN"
  );

  const wanPort = wanPorts ? wanPorts[0] : null;

  const { data: livePort, isLoading: isLivePortLoading } = useIsPortLive(
    wanPort?.device_id ?? "",
    wanPort?.id ?? "",
    {
      enabled: Boolean(wanPort?.device_id) && Boolean(wanPort?.id),
    }
  );

  const wanPortWithLive = {
    ...wanPort,
    ...livePort,
  } as any;

  const wanPortActive =
    wanPortWithLive?.data?.portinterface.live?.link_state === "up"
      ? true
      : false;

  const allPortsCollection = [
    lanOnePortWithLive,
    lanTwoPortWithLive,
    lanThreePortWithLive,
    lanFourPortWithLive,
    sfpOnePortWithLive,
    wanPortWithLive,
  ];

  const statusMessage = wanPortActive
    ? deviceHaveSubscription
      ? "Having trouble with your services? Please contact your service provider."
      : "Your device is connected. Please head to the services page to subscribe to a service."
    : "Your device is not connected. Please troubleshoot your device.";

  const statusHeader = wanPortActive ? "Status:" : "Status Report:";

  const serviceAction = (
    <ButtonService
      onClick={() => navigate(routes.services.path)}
      sx={{
        pointerEvents: "auto",
        fontSize: "16px",
        display: "inline-flex",
        color: "#fff",
      }}
    >
      More Services
    </ButtonService>
  );

  const subscriptionMessage =
    !deviceHaveSubscription && wanPortActive ? (
      <div>
        <Typography
          color={colors.textPrimary.heading}
          fontSize={getREMFromPX(12)}
          fontFamily="Inter"
          fontWeight={500}
          textAlign="left"
        >
          You are not currently subscribed to any services. Please click "more
          services" to subscribe.
        </Typography>
      </div>
    ) : (
      ""
    );

  const [openDevicesList, setOpenDevicesList] = useState<boolean>(false);
  const userId = useGetUserId();

  const { isMobile } = useViewport();

  const [showFront, setShowFront] = useState(true);

  const { data: isDeviceOn } = useIsDeviceOn(currentDevice?.id, {
    refetchOnWindowFocus: false,
    enabled: Boolean(currentDevice?.id),
    onError: () =>
      sendErrorToast("There was an error getting the device information"),
  });

  const { data: subscriptionsData, isLoading: isLoadingSubscriptions } =
    useGetMySubscriptions(userId, currentAccount?.id, {
      enabled: !!userId && !!currentAccount?.id,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    });

  const getDeviceFront = () => {
    switch (currentDevice?.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceFront;
      case "Venoen":
        return VenoenDeviceFront;
      case "Kontron":
        return ONTDeviceFront;
      default:
        return AaeonDeviceFront;
    }
  };

  const getDeviceBack = () => {
    switch (currentDevice?.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceBack;
      case "Venoen":
        return VenoenDeviceBack;
      case "Kontron":
        return ONTDeviceBack;
      default:
        return AaeonDeviceBack;
    }
  };

  const DeviceFront = getDeviceFront();
  const DeviceBack = getDeviceBack();
  const DeviceImage = showFront ? DeviceFront : DeviceBack;

  return (
    <NavContainer title="Help">
      <CardWrapper title="Device Information" actions={backAction}>
        {isLoadingSubscriptions || !currentAccount || !currentDevice ? (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <React.Fragment>
            <VbgTitle style={{ marginBottom: "14px" }}>
              <span
                style={{
                  marginRight: "1rem",
                }}
              >
                Virtual Broadband Gateway
              </span>
              {isDeviceOn && isMobile ? (
                <Box
                  component="span"
                  sx={{
                    width: "36px",
                    height: "18px",
                    borderRadius: "12px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      isDeviceOn.data.device === "ON" ? "#84e88e" : "#ff7a00",
                  }}
                >
                  <Typography
                    component="span"
                    fontFamily="Inter"
                    fontSize={getREMFromPX(10)}
                    fontWeight={700}
                    color={isDeviceOn.data.device === "ON" ? "#0b8718" : "#fff"}
                  >
                    {isDeviceOn.data.device === "ON" ? "ON" : "OFF"}
                  </Typography>
                </Box>
              ) : null}
            </VbgTitle>
            <VbgInfo>
              <Box
                display="flex"
                onClick={() => setOpenDevicesList(!openDevicesList)}
                sx={{
                  position: "relative",
                  flexDirection: {
                    lg: "row",
                    xs: "column",
                  },
                  alignItems: {
                    lg: "center",
                    xs: "start",
                  },
                }}
              >
                <Typography
                  fontSize={isMobile ? getREMFromPX(5) : getREMFromPX(16)}
                  fontWeight={600}
                  color="#000"
                  fontFamily="Inter"
                  marginRight="5.4px"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {currentDevice?.Physicallocation?.house_number &&
                  currentDevice?.Physicallocation.street ? (
                    <>
                      <LocationIcon
                        height={16}
                        width={16}
                        fill={colors.icons.default.fillColor}
                      />
                      {devices?.length > 1 ? (
                        <SelectDevice
                          sx={{
                            minWidth: "30%",
                            height: "32px",
                            borderRadius: "10px",
                            marginRight: "20px",
                            fontSize: {
                              lg: "16px",
                              xs: "12px",
                            },
                          }}
                        />
                      ) : (
                        <StreetInfo>
                          {`${currentDevice?.Physicallocation.house_number} ${currentDevice?.Physicallocation.street}`}
                        </StreetInfo>
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        fontWeight: 600,
                        color: "red",
                        fontFamily: "Inter",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "4px",
                        fontSize: "14px",
                        marginTop: "4px",
                      }}
                    >
                      <LocationIcon
                        height={16}
                        width={16}
                        fill={colors.icons.default.fillColor}
                      />
                      No Address Found
                    </Box>
                  )}
                </Typography>
                {currentDevice?.hardware_version ? (
                  <Typography
                    fontSize={isMobile ? getREMFromPX(5) : getREMFromPX(16)}
                    fontWeight={500}
                    color="#000"
                    fontFamily="Inter"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Router>
                      <RouterIcon
                        height={16}
                        width={16}
                        fill={colors.icons.default.fillColor}
                      />
                    </Router>
                    <Hardware>{currentDevice?.hardware_version}</Hardware>
                  </Typography>
                ) : null}
              </Box>
            </VbgInfo>
            <Grid container overflow={"auto"}>
              <Grid item md={12} lg={6}>
                <MyServiceRow>
                  <MyService>My Services</MyService>
                </MyServiceRow>
                {subscriptionMessage ? (
                  <Grid
                    container
                    style={{
                      marginLeft: "16px",
                      width: "97%",
                      marginBottom: "35px",
                    }}
                  >
                    <Grid item>{subscriptionMessage}</Grid>
                  </Grid>
                ) : null}
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "left" }}>
                          Port
                        </TableCell>
                        {isMobile ? (
                          <TableCell style={{ textAlign: "left" }}>
                            Info
                          </TableCell>
                        ) : (
                          <>
                            <TableCell style={{ textAlign: "left" }}>
                              Provider
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              Plan
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              Type
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              Status
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {processData(subscriptionsData, currentDevice)?.map(
                        (services: any) => {
                          return (
                            <MyDevicePortsRow
                              service={services}
                              key={services.id}
                            />
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "2rem",
                    marginTop: "24px",
                  }}
                >
                  {serviceAction}
                  <ViewMyService
                    to={routes.helpTroubleshoot.path}
                    aria-label="View Troubleshooting instructions"
                  >
                    Troubleshooting
                  </ViewMyService>
                </Box>

                <Grid
                  item
                  sx={{
                    fontSize: "0.875rem",
                    textAlign: "center",
                    marginTop: {
                      lg: "93.5px",
                      xs: "24px",
                    },
                  }}
                >
                  <p>
                    Having trouble with your services? Please contact your
                    service provider.
                  </p>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                lg={6}
                sx={{
                  zIndex: 2,
                  marginTop: {
                    lg: "235px",
                    sm: 10,
                    xs: 0,
                  },
                  order: {
                    lg: 1,
                    xs: -1,
                  },
                }}
              >
                <Box
                  sx={{
                    marginLeft: {
                      lg: "60px",
                      xs: 0,
                    },
                    marginTop: {
                      lg: 0,
                      xs: 6,
                    },
                    textAlign: "center",
                  }}
                >
                  <DeviceImage ports={allPortsCollection} />
                  <Stack
                    onClick={() => setShowFront(!showFront)}
                    sx={{
                      marginTop: getREMFromPX(10),
                      width: "100%",
                      maxWidth: getREMFromPX(550),
                      paddingRight: getREMFromPX(30),
                      alignSelf: "center",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexDirection: "row",
                      fontSize: getREMFromPX(14),
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        background: "white",
                        border: `1px solid ${colors.buttons.outline.border}`,
                        borderRadius: "4px",
                        padding: "2px 10px",
                        gap: "4px",
                        fontFamily: "Inter",
                      }}
                    >
                      Rotate
                      <RotateIcon
                        style={{
                          height: "14px",
                          width: "14px",
                        }}
                        fill={colors.icons.default.fillColor}
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    marginLeft: {
                      lg: "60px",
                      xs: 0,
                    },
                    marginTop: {
                      lg: "135px",
                      xs: 4,
                    },
                    border: {
                      md: "1px solid #DBDBDB",
                      xs: undefined,
                    },
                    padding: {
                      md: "16px 8px",
                      xs: undefined,
                    },
                    borderRadius: "8px",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginTop: "-6px" }}
                  >
                    <Grid
                      item
                      borderLeft="2px solid white"
                      sx={{
                        paddingTop: "3px",
                        paddingBottom: "4px",
                        paddingLeft: {
                          sm: "12px",
                          xs: undefined,
                        },
                      }}
                    >
                      <Typography
                        color={colors.textPrimary.heading}
                        fontSize={getREMFromPX(16)}
                        fontFamily="Inter"
                        fontWeight={800}
                        marginBottom="5px"
                      >
                        <>{statusHeader}</>
                      </Typography>
                      <Typography
                        color={colors.textPrimary.heading}
                        fontSize={getREMFromPX(12)}
                        fontFamily="Inter"
                        fontWeight={500}
                      >
                        <>{statusMessage}</>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* <Box sx={{ textAlign: "right", marginTop: "48px" }}>
              {deviceStatus}
            </Box> */}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </CardWrapper>
    </NavContainer>
  );
};
export default DeviceGuide;
