import { Box, Card, Grid, Typography } from "@mui/material";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import { OverviewDataModel } from "../../../../../components/AllServices/AllServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useViewport from "../../../../../hooks/useViewport";
import { AttachMoney } from "@mui/icons-material";
import useGetThemePath from "../../../../../hooks/useGetThemePath";

const CardContent = ({
  modalOverviewData,
}: {
  modalOverviewData: OverviewDataModel;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { isMobile } = useViewport();

  return (
    <Box>
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(isMobile ? 14 : 20)}
        fontFamily="Inter"
        color={colors.textPrimary.heading}
        fontWeight="bold"
        paddingBottom="19px"
        mt="8px"
      >
        Order Confirmation
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          flexDirection: {
            md: "row",
            xs: "column",
          },
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            width: {
              md: "50%",
              xs: "100%",
            },
            flexShrink: 0,
          }}
        >
          <Card
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              py: "30px",
              height: "100%",
              position: "relative",
              border: `1px solid ${colors.servicecard.border}`,
              boxShadow: "none",
              borderRadius: "8px",
            }}
          >
            <Box>
              <Box>
                <Box
                  style={{
                    padding: "0 20px ",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      aspectRatio: 207 / 63,
                      maxWidth: 300,
                    }}
                  >
                    <img
                      role="presentation"
                      src={`${process.env.PUBLIC_URL}/assets/provider/${modalOverviewData.provider_id}.png`}
                      alt="provider logo"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      textAlign="left"
                      fontSize={getREMFromPX(16)}
                      fontFamily="Inter"
                      color={colors.textPrimary.heading}
                      fontWeight={600}
                    >
                      {modalOverviewData.fields.Name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Grid
                  container
                  justifyContent="center"
                  display="flex"
                  columnSpacing={2}
                  rowSpacing={1.5}
                  style={{ margin: "20px" }}
                >
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <FontAwesomeIcon
                        icon={
                          modalOverviewData?.fields_icons?.[1]?.icon as IconProp
                        }
                        fill={colors.icons.default.fillColor}
                        style={{
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(16)}
                        fontFamily="Inter"
                        color={colors.textPrimary.heading}
                        fontWeight={600}
                      >
                        Link Speed:
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(16)}
                        fontFamily="Inter"
                        pl={2}
                        fontWeight={500}
                        color={colors.textPrimary.heading}
                      >
                        {modalOverviewData.fields.LinkSpeed}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <FontAwesomeIcon
                        icon={
                          modalOverviewData?.fields_icons?.[0]?.icon as IconProp
                        }
                        fill={colors.icons.default.fillColor}
                        style={{
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(16)}
                        fontFamily="Inter"
                        fontWeight={600}
                        color={colors.textPrimary.heading}
                      >
                        Up:
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(16)}
                        fontFamily="Inter"
                        pl={2}
                        fontWeight={600}
                        color={colors.textPrimary.heading}
                      >
                        {modalOverviewData.fields.Up}mb/s
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <FontAwesomeIcon
                        icon={
                          modalOverviewData?.fields_icons?.[2]?.icon as IconProp
                        }
                        fill={colors.icons.default.fillColor}
                        style={{
                          marginRight: "8px",
                        }}
                      />

                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(16)}
                        fontWeight={600}
                        fontFamily="Inter"
                        color={colors.textPrimary.heading}
                      >
                        Down:
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(16)}
                        fontFamily="Inter"
                        pl={2}
                        fontWeight={600}
                        color={colors.textPrimary.heading}
                      >
                        {modalOverviewData.fields.Down}mb/s
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      {modalOverviewData?.fields_icons?.[4]?.icon ? (
                        <FontAwesomeIcon
                          icon={
                            modalOverviewData?.fields_icons?.[4]
                              ?.icon as IconProp
                          }
                          fill={colors.icons.default.fillColor}
                          style={{
                            marginRight: "12px",
                          }}
                        />
                      ) : (
                        <AttachMoney
                          sx={{
                            marginLeft: "-4px",
                            marginRight: "4px",
                          }}
                        />
                      )}

                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(16)}
                        fontWeight={700}
                        fontFamily="Inter"
                        color={colors.textPrimary.heading}
                      >
                        Price:
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography
                        textAlign="left"
                        fontSize={getREMFromPX(14)}
                        fontFamily="Inter"
                        pl={2}
                        color={colors.textPrimary.heading}
                        fontWeight="bold"
                      >
                        ${modalOverviewData.fields.Price}/month
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Box>
        <Box
          sx={{
            width: {
              md: "50%",
              xs: "100%",
            },
            flexShrink: 0,
          }}
        >
          <Card
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "start",
              textAlign: "center",
              position: "relative",
              border: `1px solid ${colors.servicecard.border}`,
              boxShadow: "none",
              borderRadius: "8px",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              pb={1.5}
              borderBottom={`1px solid ${colors.servicecard.border}`}
            >
              <Typography
                textAlign="left"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={500}
              >
                {modalOverviewData.providerName} Fee:
              </Typography>
              <Typography
                textAlign="right"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={500}
              >
                ${modalOverviewData.fields.Price}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              pb={1.5}
              pt={1}
            >
              <Typography
                textAlign="left"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={500}
              >
                Subtotal:
              </Typography>
              <Typography
                textAlign="right"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={500}
              >
                ${modalOverviewData.fields.Price}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              pb={1.5}
              borderBottom={`1px solid ${colors.servicecard.border}`}
            >
              <Typography
                textAlign="left"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={500}
              >
                Tax:
              </Typography>
              <Typography
                textAlign="right"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={500}
              >
                $0.00
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              pb={1.5}
              borderBottom={`1px solid ${colors.servicecard.border}`}
            >
              <Typography
                textAlign="left"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={700}
                pt={1}
              >
                Monthly Total:
              </Typography>
              <Typography
                textAlign="right"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={700}
              >
                ${modalOverviewData.fields.Price}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              pb={1.5}
            >
              <Typography
                textAlign="left"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={700}
                pt={1}
              >
                Due Today:
              </Typography>
              <Typography
                textAlign="right"
                fontSize={getREMFromPX(16)}
                fontFamily="Inter"
                color={colors.textPrimary.heading}
                fontWeight={700}
              >
                ${modalOverviewData.fields.Price}
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: "start",
                color: colors.dark,
                fontSize: getREMFromPX(12),
                fontFamily: "Inter",
                fontWeight: 500,
                mt: "auto",
              }}
            >
              Note: You will be billed monthly starting from the day you sign up
              for your service. Your payment date each month will correspond to
              the date of your initial signup.
            </Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default CardContent;
