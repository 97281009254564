import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { useContext, useMemo, useState, useEffect } from "react";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../../components/AddressAndAccountProvider";
import useArePortsLive from "../../../../../hooks/useArePortsLive";
import useViewport from "../../../../../hooks/useViewport";
import Link from "../../../../../components/Link";
import SelectDevice from "../../../../../components/SelectDevice";
import InfoIcon from "@mui/icons-material/Info";
import HoverPopover from "../../../../../components/HoverPopover";
import useGetThemePath from "../../../../../hooks/useGetThemePath";

const TableContent = ({
  selectedPort,
  planName,
  setSelectedPort,
  deviceIsConnected = false,
}: {
  planName: string;
  selectedPort: string | undefined;
  setSelectedPort: (param: string | undefined) => void;
  deviceIsConnected?: boolean;
}) => {
  const { currentDevice, devices } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { isMobile } = useViewport();

  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const [showPortSelectedMessage, setShowPortSelectedMessage] = useState(false);
  const handleRadioChange = (id: string) => setSelectedPort(id);

  const devicePorts = useMemo(
    () =>
      currentDevice.Portinterfaces?.filter((port) => port.type === "LAN") ?? [],
    [currentDevice]
  );

  const { data: livePorts, isLoading: areLivePortsLoading } =
    useArePortsLive(devicePorts);

  const mappedWithLive = useMemo(
    () =>
      livePorts
        ? devicePorts.map((port) => {
            const live = livePorts.find(
              (p) => p?.data?.portinterface?.id === port?.id
            )?.data?.portinterface;
            return {
              ...port,
              live: live?.live,
            };
          })
        : devicePorts,
    [devicePorts, livePorts]
  );

  const mappedForTable = mappedWithLive.map((port) => {
    return {
      id: port.id,
      name: port.display_name,
      isActiveServiceInPort: !!port.Subscriptions?.length,
      linkState: areLivePortsLoading ? "loading..." : port.live?.link_state,
    };
  });

  useEffect(() => {
    if (mappedWithLive && !showPortSelectedMessage) {
      const availablePort = mappedWithLive.find(
        (port) =>
          port?.Subscriptions &&
          port.Subscriptions.length === 0 &&
          port.live?.link_state !== "disabled"
      );

      setSelectedPort(availablePort?.id);
      setShowPortSelectedMessage(true);
    }
  }, [mappedWithLive, setSelectedPort, showPortSelectedMessage]);

  return (
    <Box>
      {devices.length > 1 ? (
        <>
          {" "}
          <Typography
            textAlign="left"
            fontSize={getREMFromPX(20)}
            fontFamily="Inter"
            color={colors.textPrimary.heading}
            fontWeight="bold"
            py="15px"
          >
            Select a device to associate with this service
          </Typography>
          <SelectDevice sx={{ width: "100%", borderRadius: "10px" }} />
        </>
      ) : null}
      <Box>
        {!deviceIsConnected && (
          <>
            <Typography
              textAlign="center"
              fontSize={getREMFromPX(20)}
              fontFamily="Inter"
              color={colors.notification.error.primary}
              fontWeight="bold"
              pb="6px"
              pt="32px"
            >
              Your device is not connected to our systems.
              <br />
              Please make sure it is plugged into a power source.
            </Typography>
            <Typography
              textAlign="center"
              fontSize={getREMFromPX(20)}
              fontFamily="Inter"
              color={colors.textPrimary.heading}
              fontWeight="bold"
              py="12px"
            >
              For additional help please visit the{" "}
              <Link to="/help/device" size="large">
                Device Help Page.
              </Link>
            </Typography>
          </>
        )}

        {deviceIsConnected && (
          <>
            <Typography
              fontSize={getREMFromPX(20)}
              fontFamily="Inter"
              color={colors.textPrimary.heading}
              fontWeight="bold"
              pb="6px"
              pt="32px"
            >
              Select a port from the gateway to associate with {planName}
            </Typography>
            <>
              {showPortSelectedMessage && (
                <Typography
                  fontSize={getREMFromPX(16)}
                  fontFamily="Inter"
                  color={colors.textPrimary.heading}
                  fontWeight={400}
                >
                  A port has been detected and automatically selected for this
                  service.
                </Typography>
              )}
            </>
          </>
        )}
      </Box>
      <TableContainer
        sx={{
          marginTop: "14px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Port</TableCell>
              <TableCell>Active Service</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  Linked State{" "}
                  <HoverPopover
                    popoverContent={
                      <Paper
                        sx={{
                          width: 250,
                          height: "auto",
                          overflow: "auto",
                          borderRadius: "8px",
                          fontSize: 14,
                          border: "none",
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            padding: "8px 16px",
                            color: colors.white,
                          }}
                        >
                          What is link state?
                        </Box>
                        <Box
                          component="ul"
                          sx={{
                            color: colors.form.primary,
                            listStyleType: "none",
                            margin: 0,
                            padding: "8px 14px",
                            fontWeight: 500,
                            "& > li": {
                              marginBottom: "4px",
                            },
                            span: {
                              fontFamily: "Inter",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          <li>
                            "Link State" indicates your device&apos;s
                            connectivity status.
                          </li>
                          <li>
                            <span style={{ fontWeight: "bold" }}>UP:</span> Your
                            device is successfully connected.
                          </li>
                          <li>
                            <span style={{ fontWeight: "bold" }}>DOWN:</span>{" "}
                            Connection to your device is unavailable.
                          </li>
                        </Box>
                      </Paper>
                    }
                    triggerComponent={
                      <InfoIcon
                        sx={{ color: colors.form.primary }}
                        fill={colors.icons.default.fillColor}
                      />
                    }
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedForTable.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  cursor:
                    row.isActiveServiceInPort || !deviceIsConnected
                      ? "none"
                      : "pointer",
                  backgroundColor:
                    selectedPort === row.id
                      ? colors.table.background
                      : "inherit",
                  pointerEvents:
                    row.isActiveServiceInPort || !deviceIsConnected
                      ? "none"
                      : "auto",
                }}
                onClick={() => handleRadioChange(row.id)}
              >
                <TableCell>
                  <RadioGroup value={deviceIsConnected ? selectedPort : null}>
                    <FormControlLabel
                      label=""
                      disabled={row.isActiveServiceInPort || !deviceIsConnected}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color:
                                selectedPort === row.id
                                  ? colors.links.teal
                                  : colors.table.background,
                            },
                          }}
                        />
                      }
                      value={row.id}
                      onChange={() => handleRadioChange(row.id)}
                    />
                  </RadioGroup>
                </TableCell>
                <TableCell>
                  <Typography
                    fontSize={getREMFromPX(isMobile ? 12 : 16)}
                    fontFamily="Inter"
                    color={
                      row.isActiveServiceInPort || !deviceIsConnected
                        ? colors.mutedText
                        : colors.textPrimary.heading
                    }
                    fontWeight="600"
                  >
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    fontSize={getREMFromPX(isMobile ? 12 : 16)}
                    fontFamily="Inter"
                    color={
                      row.isActiveServiceInPort || !deviceIsConnected
                        ? colors.mutedText
                        : colors.textPrimary.heading
                    }
                    fontWeight="600"
                  >
                    {!deviceIsConnected
                      ? "N/A"
                      : row.isActiveServiceInPort
                      ? "Service Activated "
                      : "No Active Service"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    fontSize={getREMFromPX(isMobile ? 12 : 16)}
                    fontFamily="Inter"
                    color={
                      row.isActiveServiceInPort || !deviceIsConnected
                        ? colors.mutedText
                        : colors.textPrimary.heading
                    }
                    fontWeight="600"
                    textTransform="uppercase"
                  >
                    {!deviceIsConnected ? "N/A" : row.linkState}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableContent;
