import { useQuery, UseQueryOptions } from "react-query";
import fetcher from "../utils/fetchWrapper";

type BillingConfig = {
  data: {
    billing: {
      config: {
        billing_system: "ENTRYPOINT" | "ENTERNAL" | "UNKNOWN" | "STRIPE";
        foxy_cart: {
          cart_url: string;
          cart_update_url: string;
        };
      };
    };
  };
  duration: number;
  messages: string[];
};

const getBillingConfig = async (): Promise<BillingConfig> => {
  const response = await fetcher(
    `${process.env.REACT_APP_BE_URL}/billing/config`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error();
  }

  return await response.json();
};

export default function useBillingConfig(
  options?: UseQueryOptions<BillingConfig, Error, BillingConfig, Array<string>>
) {
  return useQuery(["billingPrices"], () => getBillingConfig(), options);
}
