import { useCallback, useContext, useState } from "react";
import Button from "../../../components/Button";
import { Plan } from "../utils/intefaces/Plan";
import { Box, TableCell, TableRow, Typography, styled } from "@mui/material";
import { renderStars } from "../utils/getStars";
import { theme } from "../../../theme";
import getREMFromPX from "../../../utils/getREMFromPX";
import { LogoContainer } from "./CardService";
import { useDeleteSubscription } from "../../../hooks/useDeleteSubscription";
import { useDeleteReview } from "../../../hooks/useDeleteReview";
import useGetUserId from "../../../hooks/useGetUserId";
import sendErrorToast from "../../../utils/sendErrorToast";
import { useQueryClient } from "react-query";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../components/AddressAndAccountProvider";
import UnsubscribePopup from "./UnsubscribePopup";
import { Review } from "../../../api/interfaces/Review";
import { InfoButtonIcon } from "../../../assets/icons";
import ReviewModal from "../../../components/ReviewModal";
import Menu from "../../../components/Menu";
import MenuItem from "../../../components/MenuItem";
import useMenuAnchor from "../../../hooks/useMenuAnchor";
import { getThemePath } from "../../../hooks/useGetThemePath";
import useViewport from "../../../hooks/useViewport";
import useBillingConfig from "../../../hooks/useBillingConfig";
import { toast } from "react-toastify";

const themePath = getThemePath();
const colors = theme[themePath].colors;

const StyledParagraph = styled("p")`
  color: ${colors.textPrimary.heading};
  font-family: "Inter";
  font-size: ${getREMFromPX(15)};
`;

const menuListProps = {
  "aria-labelledby": "profile_options_button",
};

const MySubscriptionsRow = ({
  total_reviews,
  ratings_sum,
  serviceType,
  planName,
  port,
  providerId,
  price,
  currentIndex,
  unsubscribeId,
  review,
  historyId,
  dataCy,
  status,
  isUserRatingEnabled,
}: Partial<Plan> & {
  serviceType: string;
  port: string;
  price: string;
  currentIndex: number;
  unsubscribeId: string;
  review?: Review;
  historyId: string;
  dataCy?: string;
  isUserRatingEnabled: boolean;
}) => {
  const { currentAccount, refetchUser } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const queryClient = useQueryClient();
  const { isMobile } = useViewport();

  const [isExpanded, setIsExpanded] = useState(false);

  const userId = useGetUserId();
  const { mutate: deleteSubscription, isLoading: isDeleting } =
    useDeleteSubscription({
      onSuccess: () => {
        setSelectedSubscriptionId("");
        queryClient.invalidateQueries([
          "subscriptions",
          userId,
          currentAccount.id,
        ]);
        refetchUser();
        queryClient.invalidateQueries(["ports", userId, currentAccount.id]);
        queryClient.invalidateQueries([["userInfo", userId]]);
      },
      onError: () => {
        setSelectedSubscriptionId("");
        sendErrorToast(
          "There was a problem deleting the subscription, please try again"
        );
      },
    });
  const { mutate: deleteReview } = useDeleteReview(currentAccount.id);

  const reviewsAverage =
    ratings_sum && Number(total_reviews) !== 0
      ? (Number(ratings_sum) / Number(total_reviews)).toString()
      : "0";

  const stars = renderStars(reviewsAverage);
  const toggleModal = useCallback(
    (id = "") => {
      if (isDeleting) {
        return;
      }
      setSelectedSubscriptionId((oldId) => (oldId ? "" : id));
    },
    [isDeleting]
  );

  const [openModal, setOpenModal] = useState(false);
  const { data } = useBillingConfig();
  const updateUrl = data?.data?.billing?.config?.foxy_cart?.cart_update_url;

  function handleUpdatePayment() {
    if (updateUrl) {
      window.open(updateUrl, "_blank");
    } else {
      toast.error("Failed to find payment method to update");
    }
  }

  const {
    anchorEl: reviewMenuAnchorEl,
    open: reviewOpenMenu,
    handleClick: handleReviewMenuClick,
    handleClose: handleReviewMenuClose,
  } = useMenuAnchor();

  const closeModal = useCallback(() => {
    handleReviewMenuClose();
    setOpenModal(false);
  }, [handleReviewMenuClose]);

  const isBillingPending = status === "PENDING_BILLING";

  return (
    <>
      <ReviewModal
        open={openModal}
        closeModal={closeModal}
        review={review}
        planName={planName ?? ""}
        historyId={historyId as string}
      />
      {isMobile ? (
        <TableRow
          sx={{
            "&>td": {
              background: isBillingPending
                ? colors.notification.error.accent
                : undefined,
            },
          }}
          data-cy={dataCy}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <TableCell data-cy={`my-services-row-${currentIndex}-plan`}>
            <Box
              sx={{
                padding: "8px 0px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Box data-cy={`my-services-row-${currentIndex}-plan`}>
                <StyledParagraph>{planName}</StyledParagraph>
              </Box>
              {isExpanded ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box>
                    <Box data-cy={`my-services-row-${currentIndex}-type`}>
                      <Typography
                        component="span"
                        sx={{ fontWeight: 700, mr: 1 }}
                      >
                        Provider:
                      </Typography>
                      <StyledParagraph as="span">{serviceType}</StyledParagraph>
                    </Box>
                    <Box data-cy={`my-services-row-${currentIndex}-type`}>
                      <Typography
                        component="span"
                        sx={{ fontWeight: 700, mr: 1 }}
                      >
                        Type:
                      </Typography>
                      <StyledParagraph as="span">{serviceType}</StyledParagraph>
                    </Box>
                  </Box>
                  {isUserRatingEnabled ? (
                    <Box data-cy={`my-services-row-${currentIndex}-review`}>
                      {reviewsAverage === "0" ? (
                        <>
                          <StyledParagraph
                            style={{
                              fontWeight: "500",
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontSize: getREMFromPX(14),
                              color: "#1D19E0",
                            }}
                            onClick={(event) => {
                              if (review) {
                                handleReviewMenuClick(event);
                              } else {
                                setOpenModal(true);
                              }
                            }}
                            data-cy={`my-services-row-${currentIndex}-add-review`}
                          >
                            {review ? (
                              <Box
                                sx={{
                                  paddingLeft: 2,
                                }}
                              >
                                <InfoButtonIcon
                                  fill={colors.icons.default.fillColor}
                                />
                              </Box>
                            ) : (
                              "Add Review"
                            )}
                          </StyledParagraph>
                          <Menu
                            id="review-menu"
                            anchorEl={reviewMenuAnchorEl}
                            open={reviewOpenMenu}
                            onClose={handleReviewMenuClose}
                            MenuListProps={menuListProps}
                          >
                            <MenuItem
                              onClick={() => {
                                handleReviewMenuClose();
                                setOpenModal(true);
                              }}
                            >
                              Edit Review
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleReviewMenuClose();
                                deleteReview(review?.id ?? "");
                              }}
                            >
                              Delete Review
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <StyledParagraph>{stars}</StyledParagraph>
                      )}
                    </Box>
                  ) : null}
                  <Box data-cy={`my-services-row-${currentIndex}-port`}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        border: "1px solid #efefef",
                        width: "65px",
                        height: "28px",
                      }}
                    >
                      <Typography
                        color={colors.textPrimary.heading}
                        fontSize={getREMFromPX(12)}
                        fontFamily="Inter"
                        fontWeight={500}
                        textAlign="center"
                      >
                        {port}
                      </Typography>
                      <Box
                        sx={{
                          color: "#84e88e",
                          background: "#84e88e",
                          width: "6.6px",
                          height: "6.6px",
                          borderRadius: "10px",
                          border: "1px solid #84e88e",
                          marginLeft: "4px",
                        }}
                      >
                        &nbsp;
                      </Box>
                    </Box>
                  </Box>
                  <Box data-cy={`my-services-row-${currentIndex}-unsubscribe`}>
                    <Button
                      text="Unsubscribe"
                      mode="outline"
                      sx={{
                        width: "100%",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedSubscriptionId(providerId ?? "");
                      }}
                    />
                    {updateUrl ? (
                      <Button
                        text="Update Payment"
                        mode="outline"
                        sx={{
                          background: isBillingPending
                            ? colors.notification.error.primary
                            : undefined,
                          color: isBillingPending ? colors.white : undefined,
                          ":hover": {
                            color: "black",
                          },
                          width: "100%",
                          mt: 1.5,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUpdatePayment();
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </TableCell>

          <TableCell data-cy={`my-services-row-${currentIndex}-price`}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              ${price ?? "0.00"}{" "}
              <Box
                component="button"
                sx={{
                  height: "24px",
                  width: "24px",
                  borderRadius: "8px",
                  background: colors.table.background,
                  border: "none",
                  fontSize: "1rem",
                }}
              >
                {isExpanded ? "-" : "+"}
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          sx={{
            "&>td": {
              background: isBillingPending
                ? colors.notification.error.accent
                : undefined,
            },
          }}
          data-cy={dataCy}
        >
          <TableCell data-cy={`my-services-row-${currentIndex}-port`}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                border: "1px solid #efefef",
                width: "65px",
                height: "28px",
              }}
            >
              <Typography
                color={colors.textPrimary.heading}
                fontSize={getREMFromPX(12)}
                fontFamily="Inter"
                fontWeight={500}
                textAlign="center"
              >
                {port}
              </Typography>
              <Box
                sx={{
                  color: "#84e88e",
                  background: "#84e88e",
                  width: "6.6px",
                  height: "6.6px",
                  borderRadius: "10px",
                  border: "1px solid #84e88e",
                  marginLeft: "4px",
                }}
              >
                &nbsp;
              </Box>
            </Box>
          </TableCell>
          <TableCell data-cy={`my-services-row-${currentIndex}-provider`}>
            <LogoContainer width="105" height="55">
              <img
                src={`${process.env.PUBLIC_URL}/assets/provider/${providerId}.png`}
                alt={planName + " " + providerId}
              />
            </LogoContainer>
          </TableCell>
          <TableCell data-cy={`my-services-row-${currentIndex}-plan`}>
            <StyledParagraph>{planName}</StyledParagraph>
          </TableCell>
          <TableCell data-cy={`my-services-row-${currentIndex}-type`}>
            <StyledParagraph>{serviceType}</StyledParagraph>
          </TableCell>
          <TableCell data-cy={`my-services-row-${currentIndex}-price`}>
            ${price ?? "0.00"}
          </TableCell>
          {isUserRatingEnabled ? (
            <TableCell data-cy={`my-services-row-${currentIndex}-review`}>
              {reviewsAverage === "0" ? (
                <>
                  <StyledParagraph
                    style={{
                      fontWeight: "500",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: getREMFromPX(14),
                      color: "#1D19E0",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      if (review) {
                        handleReviewMenuClick(event);
                      } else {
                        setOpenModal(true);
                      }
                    }}
                    data-cy={`my-services-row-${currentIndex}-add-review`}
                  >
                    {review ? (
                      <Box
                        sx={{
                          paddingLeft: 2,
                        }}
                      >
                        <InfoButtonIcon fill={colors.icons.default.fillColor} />
                      </Box>
                    ) : (
                      "Add Review"
                    )}
                  </StyledParagraph>
                  <Menu
                    id="review-menu"
                    anchorEl={reviewMenuAnchorEl}
                    open={reviewOpenMenu}
                    onClose={handleReviewMenuClose}
                    MenuListProps={menuListProps}
                  >
                    <MenuItem
                      onClick={() => {
                        handleReviewMenuClose();
                        setOpenModal(true);
                      }}
                    >
                      Edit Review
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleReviewMenuClose();
                        deleteReview(review?.id ?? "");
                      }}
                    >
                      Delete Review
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <StyledParagraph>{stars}</StyledParagraph>
              )}
            </TableCell>
          ) : null}
          <TableCell data-cy={`my-services-row-${currentIndex}-unsubscribe`}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "end",
              }}
            >
              <Button
                text="Unsubscribe"
                mode="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedSubscriptionId(providerId ?? "");
                }}
              />
              {updateUrl ? (
                <Button
                  text="Update Payment"
                  mode="outline"
                  sx={{
                    background: isBillingPending
                      ? colors.notification.error.primary
                      : undefined,
                    color: isBillingPending ? colors.white : undefined,
                    ":hover": {
                      color: "black",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdatePayment();
                  }}
                />
              ) : null}
            </Box>
          </TableCell>
        </TableRow>
      )}
      <UnsubscribePopup
        open={!!selectedSubscriptionId}
        onClickSubscribe={() => deleteSubscription(unsubscribeId)}
        onClose={() => toggleModal()}
        isLoading={isDeleting}
        name={serviceType as string}
        price={price as string}
        selectedId={providerId as string}
      />
    </>
  );
};

export default MySubscriptionsRow;
